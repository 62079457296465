@import "~breakpoint-sass";

$bk-desktop: 1920px 1440px;
$bk-table-landscape: 1440px 811px;
$bk-table: 811px 611px;
$bk-phone: 611px 0px;

.wrapper {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  gap: 12px;
  padding: 80px 0;
  background-color: #C8EAFF;
  @include breakpoint($bk-phone) {
    padding: 24px 0;
    gap: 24px;
  }
}

.logoWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 61px;
  @include breakpoint($bk-phone) {
    margin-bottom: 12px;
  }
}

.about {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 15px;
  gap: 85px;
  
  @include breakpoint($bk-phone) {
    gap: 12px;
  }

  h1 {
    color: rgba(109, 109, 109, 0.80);
    font-family: DurkWideCyTT, sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    text-shadow: 4px 4px 3px #AEDDFB;
  }

  @include breakpoint($bk-phone) {
    flex-flow: column;
    padding: 24px;
    display: flex;
    align-items: start;
    h1 {
      font-size: 20px;
    }
  }
}

.payPage {
  justify-content: center;
  align-items: center;
  padding: 0;
}

.CardpayPage {
  display: flex;
  flex-flow: column;
  text-align: center;
  gap: 30px;
  padding: 40px;
  height: 100%;
  width: auto;
  min-width: 304px;
  max-width: 500px;
  border-radius: 27px;
  align-items: center;
  background-color: white;

  .WrapperButtonPayPage {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
    gap: 12px;

    button {
      max-width: none;
      @include breakpoint($bk-phone) {
        font-size: 18px;
        height: auto;
        min-height: 68px;
      }
    }
  }
}

.CardsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 30px 30px;
  gap: 30px;

  @include breakpoint($bk-phone) {
    flex-flow: column;
    padding: 0 15px;
  }

    @include breakpoint(1110px 811px) {
    flex-flow: column;
    padding: 0 15px;
  }

  .Card {
    display: flex;
    flex-flow: column;
    text-align: center;
    gap: 30px;
    padding: 40px;
    height: 100%;
    width: auto;
    min-width: 304px;
    max-width: 500px;
    border-radius: 10px;
    border: 3px solid #FFF;
    align-items: center;

    h1 {
      color: #FFF;
      text-align: center;
    
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 11px; /* 45.833% */
    }
  }

  .PriceWrapper {
    height: auto;
    width: 100%;
    display: grid;
    justify-content: center;
    gap: 8px;
  }

  .price {
    height: auto;
    display: flex;
    color: #6D6D6D;
    font-size: 16px;
    font-style: normal;
    font-weight: 590;
    justify-content: space-between;
    gap: 24px;
  }
}

.ButtonPrimary {
  border-radius: 5px;
  background: rgba(49, 174, 255, 0.60);
  max-width: 212px;
}

.FAQWrapper {
  width: 100%;
  padding: 24px;
  display: flex;
  flex-flow: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
  text-align: center;

  h1 {
    font-family: DurkWideCyTT,sans-serif;
    color: #FFF;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    text-shadow: 1px 1px 20px rgba(49, 174, 255, 0.60);
    font-size: 29px;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
  }
}

.Contacts {
  display: flex;
  flex-flow: column;
}

.footer {
  width: 100%;
  padding: 40px 60px;
  display: flex;
  flex-flow: column;
  gap: 24px;
  background-image: url("../../public/logo-white.png");
  background-size: 100%;
  background-position: bottom;
  background-repeat: no-repeat;

  @include breakpoint($bk-desktop) {
    min-width: 1200px;
  }

  @include breakpoint($bk-table-landscape) {
    min-width: 950px;
  }


  svg {
    @include breakpoint($bk-phone) {
      max-width: 300px;
    }
  }

  @include breakpoint($bk-phone) {
    padding: 24px;
  }

  a {
    color: #6D6D6D;
  }

  .docs {
    display: flex;
    width: 100%;
    justify-content: space-between;

    @include breakpoint($bk-phone) {
      flex-flow: column;
      gap: 12px;
    }
  }

  .EP {
    display: flex;
    gap: 32px;

    @include breakpoint($bk-phone) {
      flex-flow: column;
    }
  }
}

@include breakpoint($bk-phone) {
  svg {
    max-width: 300px;
  }
}
