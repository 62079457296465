@import '~breakpoint-sass';

$bk-desktop: 8000px 800px;
//$bk-table-landscape: 1440px 811px;
//$bk-table: 811px 611px;
$bk-phone: 800px 0px;

.wrapper {
  height: auto;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.BlockWrapper1 {
  height: auto;
  position: relative;
  width: 100%;
  padding: 24px 40px;
  @include breakpoint($bk-phone) {
    padding: 16px 16px;
  }
}

.block1 {
  font-family: "SF Pro Text", sans-serif;
  //background-image: url("../../public/new/bg.png");
  //background-size: cover;
  //background-repeat: no-repeat;
  border-radius: 30px;
  padding: 24px 78px;
  color: white;
  text-align: center;
  //noinspection CssInvalidPropertyValue
  height:95svh;
  width: 100%;
  //position: relative;
  overflow: hidden;

  @include breakpoint($bk-phone) {
    padding: 16px 16px;
  }

  .headerMobile {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px;
    z-index: 999;
    @include breakpoint($bk-desktop) {
      display: none;
    }

    button {
      height: 36px;
      width: 38px;
      background-color: transparent;
      position: relative;

      img {
        position: absolute;
        top: 2%;
        right: 2%;
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 19px 72px;
    width: 100%;
    position: absolute;
    left: 0;

    @include breakpoint($bk-phone) {
      display: none;
    }
  }

  .logo {
    font-size: 1.2rem;
    font-weight: bold;
  }

  .nav {
    display: flex;
    gap: 40px;
  }

  .navLink {
    display: flex;
    flex-flow: column;
    cursor: pointer;

    color: var(--White, #FFF);
    text-align: center;
    font-family: "SF Pro Text", sans-serif;
    text-decoration: none;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 14.547px; /* 90.92% */
    letter-spacing: -0.32px;
    text-transform: uppercase;
    transition: 0.5s;

    &:hover {
      transition: 0.5s;
      color: #1079AA;
      svg {
        stroke: #1079AA;
      }
    }
  }

  .questionButton {
    width: auto;
    height: 40px;
    background: none;
    border: 1px solid white;
    padding: 8px 42px;
    border-radius: 60px;
    font-size: 1rem;
    color: white;
    cursor: pointer;
    transition: background 0.3s;

    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 183.333% */

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }

  .mainContent {
    margin-top: 50px;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 56px;
    width: 100%;
  }

  .title {
    font-size: 3rem;
    font-weight: bold;
  }

  .description {
    margin-top: 10px;
    max-width: 833px;
    color: var(--White, #FFF);
    text-align: center;
    font-family: "SF Pro Text", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 110% */
    letter-spacing: -0.4px;
  }

  .signupButton {
    width: 290px;
    height: 72px;
    background: var(--Blue, #6DC6FE);
    border: none;
    border-radius: 99px;
    padding: 10px 20px;
    margin-top: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: relative;
    overflow: hidden; // Добавляем overflow, чтобы скрыть части иконок

    color: var(--White, #FFF);
    font-family: "SF Pro Text", sans-serif;
    font-size: 14px;
    line-height: 30.207px; /* 215.766% */
    transition: background 0.3s;

    .buttonText {
      transition: margin 1s ease; // Плавный переход для смещения текста
      margin-left: 32px; // Изначально чуть правее от левой иконки
    }

    &:hover {
      background-color: white;
      color: #6DC6FE;

      .arrowLeft {
        transition: transform 0.6s ease, opacity 0.61s ease; // Увеличиваем время анимации
        transform: scale(0); // Уменьшаем до нуля
        opacity: 0; // Делаем прозрачной
      }

      .arrowRight {
        transition: transform 0.6s ease, opacity 0.6s ease; // Увеличиваем время анимации
        transform: scale(1); // Увеличиваем
        opacity: 1; // Делаем видимой
      }

      .buttonText {
        margin-left: -32px; // Смещаем текст влево при наведении
      }
    }

    .arrowLeft, .arrowRight {
      position: absolute;
      width: 49px; // Ширина иконок
      height: 49px; // Высота иконок
    }

    .arrowLeft {
      left: 16px; // Положение левой иконки
      opacity: 1; // Изначально скрываем правую иконку
      transform: scale(1); // Изначально скрываем правую иконку
      transition: transform 1s ease, opacity 1s ease; // Добавляем переходы для правой иконки
    }

    .arrowRight {
      right: 16px; // Положение правой иконки
      opacity: 0; // Изначально скрываем правую иконку
      transform: scale(0); // Изначально скрываем правую иконку
      transition: transform 1s ease, opacity 1s ease; // Добавляем переходы для правой иконки
    }
  }
}

.block2 {
  width: 100%;
  height: auto;
  padding: 80px 0;
  gap: 100px;
  @include breakpoint($bk-phone) {
    gap: 64px;
  }

  display: flex;
  flex-flow: column;
  align-items: center;

  .titleControls {
    display: grid;
    width: 95%;
    position: relative;

    .controls {
      display: flex;
      gap: 20px;
      margin-bottom: 20px;
      position: absolute;
      right: 0;
      top: 10px;
    }

    .scrollButton {
      display: flex;
      width: 77px;
      height: 42px;
      padding: 18px 25px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      border-radius: 30px;
      background: var(--White, #FFF);
      box-shadow: -3px 0px 20px 0px rgba(75, 78, 80, 0.15);

      &:hover {
        background-color: #003f7f;
      }
    }
  }

  .CardWrapper {
    width: 100%;
    overflow-x: scroll;
    display: flex;
    gap: 20px;
    scroll-behavior: smooth;
    padding: 40px 40px;
    @include breakpoint($bk-desktop) {
      margin: -40px 0;
    }

    @include breakpoint($bk-phone) {
      padding: 16px 16px;
    }

    /* Убирает вертикальную полосу прокрутки */
    &::-webkit-scrollbar {
      height: 0px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
      border-radius: 4px;
    }
  }

  .Card {
    color: white;
    flex: 0 0 auto;
    width: 400px;
    height: 456px;
    padding: 32px 24px;
    background-color: #0056b3;
    position: relative;
    border-radius: 30px;
    display: flex;
    flex-flow: column;
    gap: 12px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 0.3s ease;  // Добавляем плавность анимации

    .Counter {
      color: var(--White, #FFF);
      font-family: "SF Pro Text", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px; /* 137.5% */
      letter-spacing: -0.32px;
    }

    .Title {
      color: var(--White, #FFF);
      font-family: "SF Pro Text", sans-serif;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px; /* 92.857% */
      letter-spacing: -0.56px;
      white-space: pre-line;
    }

    .descriptionWrapper {
      opacity: 0;
      transition: 0.5s;
      display: flex;
      padding: 32px 24px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;
      width: 100%;
      height: 210px;
      position: absolute;
      bottom: 0;
      left: 0;
      border-radius: 30px 30px 0 0;
      background: rgba(255, 255, 255, 0.20);
      @include breakpoint($bk-phone) {
        opacity: 1;
      }


      .description {
        color: var(--White, #FFF);
        text-align: center;
        font-family: "SF Pro Text", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 21px;
      }
    }

    &:hover {
      transform: scale(1.05);
      @include breakpoint($bk-phone) {
        hover: none;
      }// Увеличиваем карточку на 5%
      .descriptionWrapper {
        opacity: 1;
      }
    }
  }
}

.BlockTitles {
  color: var(--Blue, #6DC6FE);
  text-align: center;
  font-family: "SF Pro Text", sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: 54px; /* 112.5% */
  letter-spacing: -1.92px;

  @include breakpoint($bk-phone) {
    font-size: 32px;
    line-height: 36px;
  }
}

.block3 {
  font-family: "SF Pro Text", sans-serif;
  width: 100%;
  max-width: 1440px;
  height: auto;
  padding: 80px 40px;
  gap: 100px;
  display: flex;
  flex-flow: column;
  align-items: center;
  position: relative;

  @include breakpoint($bk-phone) {
    padding: 16px 16px;
    gap: 64px;
  }

  .bgShape {
    position: absolute;
    right: -50px;
    z-index: 1;

    @include breakpoint($bk-phone) {
      display: none;
    }
  }

  .ProgrammWrapper {
    z-index: 3;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 40px;

    @include breakpoint($bk-phone) {
      padding: 0;
      margin-bottom: 80px;
      display: flex;
      flex-flow: column;
      gap: 16px;
    }

    .ModuleCard {
      display: flex;
      flex-flow: column;
      gap: 16px;
      width: 100%;
      height: 233px;
      padding: 80px 24px 24px 40px;
      border-radius: 30px;
      box-shadow: -3px 0px 50px 0px rgba(75, 78, 80, 0.10);
      position: relative;
      background-size: 130%;
      background-position: center;
      background-repeat: no-repeat;
      transition: transform 0.3s ease;  // Добавляем плавность анимации

      @include breakpoint($bk-phone) {
        background-size: 150%;
        padding: 55px 24px 60px 24px;
      }

      &:hover {
        transform: scale(1.05);
      }

      @include breakpoint($bk-phone) {
        hover: none;
      }


      .ModuleCardTitle {
        color: var(--main-blue, #6DC6FE);
        font-family: "SF Pro Text", sans-serif;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -1.44px;
      }

      .ModuleCardName {
        color: var(--Black, #303030);
        font-family: "SF Pro Text", sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.36px;
        white-space: pre-line;
      }

      .ModuleCardButton {
        width: 56px;
        height: 56px;
        border-radius: 100px;
        position: absolute;
        right: 24px;
        bottom: 24px;
        background-color: #6DC6FE;
        background-image: url("../../public/new/btn.png");
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}

.Line {
  border: 0.5px solid #D9DADF;;
  width: 100%;
  color: black;
}

.block4 {
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;
  padding: 0 40px;

  @include breakpoint($bk-phone) {
    padding: 0;
    gap: 64px;
  }

  .TabBlocks {
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 32px;
    width: 100%;
    height: auto;
    padding: 40px;
    border-radius: 30px;
    background: var(--White, #FFF);
    box-shadow: -3px 0px 50px 0px rgba(75, 78, 80, 0.10);

    @include breakpoint($bk-phone) {
      padding: 24px 12px;
    }
  }

  .Gap {
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 40px;
  }

  .tabs {
    width: 75%;
    display: flex;
    gap: 10px;
    @include breakpoint($bk-phone) {
      width: 100%;
      overflow-x: scroll;
      &::-webkit-scrollbar {
        height: 0px;
      }
    }
  }

  .tabButton {
    color: black;
    line-height: 22px; /* 157.143% */
    text-transform: uppercase;
    font-family: "SF Pro Text", sans-serif;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 20px;
    border: none;
    background-color: #FAFAFB;
    cursor: pointer;
    border-radius: 30px;
    transition: background-color 0.3s;

    &.active {
      background-color: #6DC6FE;
      color: white;
    }

    @include breakpoint($bk-phone) {
      width: 100% !important;
      padding: 0 30px;
    }
  }

  //PC
  .contentBlock {
    position: relative; /* Для позиционирования ::before */
    width: 100%;
    height: 509px;
    padding: 48px 40px;
    border-radius: 30px;
    color: white;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    gap: 32px;
    text-align: left;
    white-space: pre-line;
    transition: 0.5s;
    @include breakpoint($bk-phone) {
      display: none;
    }

    .Text {
      display: flex;
      flex-flow: column;
      gap: 40px;
      color: #303030;
    }

    .ImageBlock {
      width: 100%;
      height: 100%;
      border-radius: 30px;
      background-repeat: no-repeat;
      background-size: cover;
    }

    h2 {
      font-family: "SF Pro Text", sans-serif;
      font-size: 40px;
      font-weight: 700;
      letter-spacing: -1.6px;
      margin: 0;
      @include breakpoint($bk-phone) {
        font-size: 20px;
      }
    }

    p {
      color: var(--Black, #303030);
      font-family: "SF Pro Text", sans-serif;
      font-size: 19px;
      font-style: normal;
      font-weight: 400;
      @include breakpoint($bk-phone) {
        font-size: 16px;
      }
    }
  }

  .contentBlockMobile {
    width: 100%;
    height: 500px;
    padding: 8px;
    border-radius: 30px;
    color: white;
    display: grid;
    align-items: end;
    gap: 40px;
    background-size: cover;
    white-space: pre-line;
    @include breakpoint($bk-desktop) {
      display: none;
    }

    .TextBlock {
      height:  max-content;
      display: flex;
      padding: 12px 16px;
      flex-direction: column;
      justify-content: end;
      align-items: flex-start;
      gap: 20px;

      border-radius: 30px;
      border: 2px solid rgba(255, 255, 255, 0.30);
      background: rgba(192, 192, 192, 0.10);
      backdrop-filter: blur(15px);

      h2 {
        font-family: "SF Pro Text", sans-serif;
        font-size: 24px;
        font-weight: 700;
      }

      p {
        color: var(--White, #FFF);
        font-family: "SF Pro Text",sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 21px; /* 131.25% */
      }
    }
  }

  .placeholderBlocks {
    display: flex;
    width: 100%;
    gap: 40px;

    @include breakpoint($bk-phone) {
      flex-flow: column;
      padding: 0 12px;
    }
  }

  .placeholder {
    display: flex;
    width: 100%;
    height: auto;
    padding: 56px 40px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;

    border-radius: 30px;
    background: var(--White, #FFF);
    box-shadow: -3px 0px 50px 0px rgba(75, 78, 80, 0.10);

    .imgPlaceholder {
      height: 193px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #666;
    }

    h3 {
      color: var(--Black, #303030);
      text-align: center;
      font-family: "SF Pro Text", sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; /* 116.667% */
      letter-spacing: -0.96px;
    }

    p {
      color: var(--Black, #303030);
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: "SF Pro Text", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 137.5% */
      letter-spacing: -0.32px;
    }
  }
}

.block5 {
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: auto;
  width: 100%;
  padding: 100px 0;

  @include breakpoint($bk-phone) {
    gap: 64px;
  }

  .CardWrapper {
    display: flex;
    align-items: center;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    height: auto;
    width: 100%;
    gap: 40px;

    @include breakpoint($bk-desktop){
      height: 650px;
    }

    /* Убирает вертикальную полосу прокрутки */
    &::-webkit-scrollbar {
      height: 0px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
      border-radius: 4px;
    }
  }

  .Card {
    flex: 0 0 150px;
    height: 456px;
    min-width: 356px;
    margin: 10px;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.05);  // Увеличиваем карточку на 5%

      .descriptionWrapper {
        opacity: 1;
      }}
  }

  @include breakpoint($bk-desktop){
    .Card.active {
      transform: scale(1.2);
    }
  }

  .Shape5 {
    position: absolute;
    @include breakpoint($bk-phone) {
      z-index: -3;
    }
  }

  .titleControls {
    display: grid;
    width: 95%;
    position: relative;

    .controls {
      display: flex;
      gap: 20px;
      margin-bottom: 20px;
      position: absolute;
      right: 0;
      top: 10px;
    }

    .scrollButton {
      display: flex;
      width: 77px;
      height: 42px;
      padding: 18px 25px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      border-radius: 30px;
      background: var(--White, #FFF);
      box-shadow: -3px 0px 20px 0px rgba(75, 78, 80, 0.15);

      &:hover {
        background-color: #003f7f;
      }
    }
  }
}



.wrapper6 {
  height: auto;
  display: flex;
  flex-flow: column;
  gap: 100px;

  @include breakpoint($bk-phone) {
    gap: 64px;
  }
}

.block6 {
  width: 100%;
  max-width: 1440px;
  height: auto;
  display: flex;
  gap: 40px;
  justify-content: center;
  padding: 0 40px;

  @include breakpoint($bk-phone) {
    padding: 0 12px;
    flex-flow: column;
    gap: 40px;
  }

  .planCard {
    width: 100%;
    text-align: center;
    color: #333;
    display: flex;
    flex-flow: column;
    gap: 24px;
  }

  .planLevel {
    background-image: url("../../public/new/bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    padding: 40px 24px;
    font-size: 36px;
    font-weight: 700;
    font-family: DrukWideCyTTMedium, sans-serif;
    display: flex;
    flex-flow: column;
    gap: 24px;
    border-radius: 30px;
  }

  .Price1 {
    display: flex;
    padding: 40px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;

    border-radius: 30px;
    background: var(--White, #FFF);
    box-shadow: -3px 0px 50px 0px rgba(75, 78, 80, 0.10);
  }

  .planTitle {
    color: var(--White, #FFF);
    text-align: center;
    font-family: "SF Pro Text", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.64px;
  }

  .planDescription {
    color: var(--Black, #303030);
    text-align: center;
    font-family: "SF Pro Text", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 122.222% */
    letter-spacing: -0.36px;
    text-transform: uppercase;
    min-height: 130px;
  }

  .featuresList {
    list-style: none;
    display: flex;
    flex-flow: column;
    gap: 24px;
  }

  .bold {
    font-weight: 700;
    text-decoration-line: underline;
  }

  .GOD {
    background: var(--Blue, #6DC6FE);

    .planDescription {
      color: white;
    }

    .featureItem {
      color: #F2F9FD;
      @include breakpoint($bk-phone) {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.32px;
      }
    }

    .pricing {
      background-color: white;

      p {
        color: #6DC6FE;
      }
    }

    .PrimaryButton, .SecondryButton, .ButtonLink {
      color: white;
    }

    .SecondryButton {
      border: 1.5px dashed var(--Blue, white);
    }
  }

  .featureItem {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    text-align: left;
    color: #333;
    @include breakpoint($bk-phone) {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.32px;
    }
  }

  .featureItemDisabled {
    display: flex;
    align-items: center;
    text-align: left;
    gap: 10px;
    font-size: 14px;
    color: #bbb;
    text-decoration: line-through;
  }

  .featureIcon {
    color: #6cb4f6;
  }

  .featureIconGray {
    color: #bbb;
  }

  .pricing {
    width: 100%;
    border-radius: 20px;
    background: var(--Blue, #6DC6FE);
    padding: 20px 24px;
    display: flex;
    flex-flow: column;
    gap: 40px;

    .titlePrice {
      color: var(--White, #FFF);
      font-family: DrukWideCyTTMedium, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px; /* 183.333% */
    }

    .pricePerMonth {
      color: var(--White, #FFF);
      font-family: DurkWideCyTT, sans-serif;
      font-size: 24px;
    }

    .priceDiscount {
      color: var(--Dark-blue, #1079AA);
      font-family: "SF Pro Text", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 110% */
      text-decoration: line-through;
    }
  }

  .buyButton, .installmentButton, .reserveButton {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
    cursor: pointer;
  }

  .ButtonWrapper {
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 12px;
  }
}

.block7 {
  width: 100%;
  height: auto;
  padding: 80px 0;
  gap: 100px;
  @include breakpoint($bk-phone) {
    gap: 64px;
  }

  display: flex;
  flex-flow: column;
  align-items: center;

  .Shape5 {
    position: absolute;
    @include breakpoint($bk-phone) {
      z-index: -1;
    }
  }

  .titleControls {
    display: grid;
    width: 95%;
    position: relative;

    .controls {
      display: flex;
      gap: 20px;
      margin-bottom: 20px;
      position: absolute;
      right: 0;
      top: 10px;
    }

    .scrollButton {
      display: flex;
      width: 77px;
      height: 42px;
      padding: 18px 25px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      border-radius: 30px;
      background: var(--White, #FFF);
      box-shadow: -3px 0px 20px 0px rgba(75, 78, 80, 0.15);

      &:hover {
        background-color: #003f7f;
      }
    }
  }

  .CardWrapper {
    width: 100%;
    overflow-x: scroll;
    display: flex;
    gap: 20px;
    scroll-behavior: smooth;
    padding: 40px 40px;
    margin: -40px 0;

    /* Убирает вертикальную полосу прокрутки */
    &::-webkit-scrollbar {
      height: 0px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
      border-radius: 4px;
    }
  }

  .Card {
    color: white;
    flex: 0 0 auto;
    width: 400px;
    height: 456px;
    padding: 32px 24px;
    position: relative;
    border-radius: 30px;
    display: flex;
    flex-flow: column;
    justify-content: end;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: 0.5s;

    .descriptionWrapper {
      display: flex;
      padding: 16px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      align-self: stretch;

      border-radius: 20px;
      background: rgba(255, 255, 255, 0.60);
      box-shadow: 0px -12px 12px 0px rgba(149, 149, 149, 0.10) inset, -15.667px 15.667px 15.667px 0px rgba(255, 255, 255, 0.10) inset;
      backdrop-filter: blur(5px);

      color: var(--Black, #303030);
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 110% */
      letter-spacing: -0.4px;


    }

    &:hover {
      transform: scale(1.05);
    }
  }
}

//block8
.SupportBlockWrapper {
  width: 100%;
  height: auto;
  padding: 80px 40px;
  @include breakpoint($bk-phone) {
    padding: 40px 12px;
  }

  .SupportBlock {
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    width: 100%;
    height: auto;
    padding: 100px 78px 100px 77px;
    justify-content: center;
    gap: 79px;

    @include breakpoint($bk-phone) {
      padding: 40px 16px;
      display: flex;
      flex-flow: column;
      text-align: center;
      gap: 40px;
    }

    border-radius: 30px;
    background: var(--White, #FFF);
    box-shadow: -3px 0px 50px 0px rgba(75, 78, 80, 0.10);

    .Text {
      display: flex;
      flex-flow: column;
      gap: 40px;
      @include breakpoint($bk-phone) {
      gap: 32px;
    }

      h3 {
        color: var(--Black, #303030);
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 42px; /* 105% */
        letter-spacing: -1.6px;
      }

      p {
        color: var(--Black, #303030);
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; /* 130% */
        letter-spacing: -0.4px;
      }
    }

    .Form {
      width: 100%;
      display: flex;
      flex-flow: column;
      gap: 16px;

      .InputWrapper {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 24px;
        @include breakpoint($bk-phone) {
          display: flex;
          flex-flow: column;
          gap: 16px;
        }
      }
    }

    input {
      width: 100%;
      max-width: none;
      display: flex;
      height: 55px;
      align-items: center;
      align-self: stretch;
      border-radius: 30px;
      //padding: 0 0 0 40px;
      border: 1px solid var(--Blue, #6DC6FE);
      color: #ACACAC;
    }
  }

  .FormButtonWrapper {
    display: flex;
    gap: 24px;
    margin-top: 16px;

    @include breakpoint($bk-phone) {
      display: flex;
      flex-flow: column;
      text-align: left;
    }


    .hight {
      height: 55px;
      background-color: #6DC6FE;

      color: var(--White, #FFF);
      leading-trim: both;
      text-edge: cap;
      font-family: "SF Pro Text", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px; /* 183.333% */
    }

    p {
      color: var(--Dop-gray, #ACACAC);
      font-family: Tahoma;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      text-decoration-line: underline;
    }
  }
}

.PrimaryButton {
  background-color: #6cb4f6;
  color: #fff;
  border-radius: 60px;
  background: var(--Dark-blue, #1079AA);

  font-family: "SF Pro Text", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 183.333% */

  display: flex;
  height: 44px;

  padding: 16px 12px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.SecondryButton {
  background-color: transparent;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--Blue, #6DC6FE);
  font-family: "SF Pro Text", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 183.333% */
  padding: 16px 12px;

  border-radius: 60px;
  border: 1.5px dashed var(--Blue, #6DC6FE);
}

.ButtonLink {
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  text-transform: uppercase;
  transition: 0.4s;

  text-align: center;
  font-style: normal;
  font-weight: 700;
  text-decoration-line: underline;

  color: var(--Blue, #6DC6FE);
  font-family: "SF Pro Text", sans-serif;
  font-size: 12px;
  line-height: 22px; /* 183.333% */

  &:hover {
    color: #AEDDFB;
    transition: 0.4s;
  }
}

@include breakpoint($bk-phone) {
  .Card {
    width: 100% !important;
  }
  .controls {
    display: none !important;
    z-index: 5;
  }
}

.MobileControls {
  display: flex;
  gap: 12px;
  margin-top: -40px;


  @include breakpoint($bk-desktop) {
    display: none;
  }

  .scrollButton {
    display: flex;
    width: 77px;
    height: 42px;
    padding: 18px 25px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: 30px;
    background: var(--White, #FFF);
    box-shadow: -3px 0px 20px 0px rgba(75, 78, 80, 0.10);

    &:hover {
      background-color: #003f7f;
    }
  }
}