@import '~breakpoint-sass';

$bk-desktop: 8000px 800px;
//$bk-table-landscape: 1440px 811px;
//$bk-table: 811px 611px;
$bk-phone: 800px 0px;

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  @include breakpoint($bk-phone) {
    align-items: end;
  }
  }

.modalContent {
  max-height: 100vh;
  background-color: white;
  padding: 56px 40px;
  width: 1440px;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 24px;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
}

@include breakpoint($bk-phone) {
  .modalContent {
    width: 100%;
    height: 90%;
    border-radius: 20px 20px 0 0;
    padding: 24px 16px;
    overflow-y: scroll;
    position: relative;
  }

  .CardsWrapper, .FlexWrapper  {
    display: flex !important;
    flex-flow: column !important;
    width: 100% !important;
    margin-top: 24px;
  }

  .card, .BonusCard {
    display: flex;
    width: 100%;
    max-width: none !important;
    z-index: 2;
  }

  .closeButton {
    position: fixed !important;
    top: 10% !important;
    right: 2%;
    z-index: 999 !important;
  }
}

.FlexWrapper {
  width: auto;
  display: flex;
  align-items: center;
  gap: 24px;
}

.CardsWrapper {
  width: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: 12px;
}

.closeButton {
  z-index: 2;
  color: black;
  font-size: 42px;
  background: none;
  border: none;
  cursor: pointer;
  width: 56px;
  position: absolute;
  right: 1.5%;
  top: 0.5%;

  &:hover {
    color: #0056b3;
    background-color: transparent;
    cursor: pointer;
  }
}

.header {
  display: flex;
  flex-flow: column;
  gap: 8px;
  height: auto;
}

.moduleContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 30px;
}

.card {
  border: 1px dashed #0096ff;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  max-width: 280px;
  display: flex;
  flex-flow: column;
  gap: 16px;
}

.number {
  color: var(--Blue, #6DC6FE);
  font-family: DrukWideCyTTMedium, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.2px;
}

.cardTitle {
  color: var(--Blue, #6DC6FE);
  font-family: DrukWideCyTTMedium, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.cardDescription {
  color: var(--Black, #303030);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.28px;
}

.bonusSection {
  background-color: #0096ff;
  padding: 20px;
  border-radius: 10px;
  color: white;
}

.BonusCard {
  width: 100%;
  max-width: 280px;
  min-width: 262px;
  padding: 24px;
  border-radius: 20px;
  background: var(--Blue, #6DC6FE);
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 24px;

  .bonusTitle {
    color: var(--White, #FFF);
    font-family: DrukWideCyTTMedium, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .list {
    display: flex;
    flex-flow: column;
    gap: 12px;
  }

  li {
    color: var(--White, #FFF);
    font-size: 14px;
    font-weight: 600;
    text-align: left;
  }
}

.bgShape {
  position: absolute;
  top: -10%;
  right: 0;
  z-index: 1;
}

.Line {
  width: 100%;
  color: white;
  border: 1px dashed white;
}

.star {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  font-family: DrukWideCyTTMedium,sans-serif;
  font-size: 11px;
  color: #6DC6FE;
}

.CardsWrapperCase {
  display: flex;
  flex-flow: column;
  gap: 24px ;
  padding-top: 80px;

  .avatar {
    height: 150px;
    width: 150px;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 100%;
  }

  .name {
    color: var(--Black, #303030);
    font-family: "SF Pro Text", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 110% */
  }

  .tg {
    color: var(--Black, #303030);
    font-family: "SF Pro Text", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 183.333% */
  }

  .cardCase  {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;

    border-radius: 20px;
    border: 1px dashed var(--Blue, #6DC6FE);
  }
  .dsc {
    color: var(--Black, #303030);
    font-family: "SF Pro Text",  sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.32px;
  }
}

.CaseList {
  display: flex;
  gap: 18px;

  img {
    height: 24px;
    width: 24px;
    object-fit: cover;
  }
}

.headerCase {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.CaseMOdal {
  max-width: 663px;
  width: 100%;
}