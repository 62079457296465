/* ContactForm.css */
@import "~breakpoint-sass";

$bk-desktop: 1920px 1440px;
$bk-table-landscape: 1440px 811px;
$bk-table: 811px 611px;
$bk-phone: 611px 0px;

.wrapperApp {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.wrapperContent {
  max-width: 1200px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-top: 6rem;
  padding: 24px;

  @include breakpoint($bk-phone) {
    margin-top: 50px;
  }
}

.logoWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 61px;
}

.infoText {
  color: #6D6D6D;
  text-align: center;
  font-family: SF Pro, sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 510;
  //line-height: 35px; /* 140% */
  max-width: 794px;

  margin-bottom: 47px;

  @include breakpoint($bk-phone) {
    font-size: 15px;
    margin-bottom: 32px;
  }
}

.colorBlock, {
  color: #FFF;
  text-align: center;
  font-family: SF Pro, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 510;
  text-transform: uppercase;

  background: #AEDDFB;
  height: 47px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 26px;

  @include breakpoint($bk-phone) {
    height: auto;
    padding: 12px;
    word-wrap: break-word;
    font-size: 15px;
  }
}

.two {
  width: 286px;

  @include breakpoint($bk-phone) {
    margin-bottom: 0;
  }
}

.twotwo {
  max-width: 559px;
  width: 100%;
}

.pointsText {
  display: grid;
  justify-content: center;
  gap: 20px;

  color: #6D6D6D;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 274;

  margin-bottom: 34px;

  @include breakpoint($bk-phone) {
    font-size: 15px;
  }
}

.line {
  width: 100%;
  height: 1px;
  flex-shrink: 0;

  background: #D9D9D9;
}

.WebinarPage {
  margin-bottom: 37px;
}

.titleColor {
  color: #AEDDFB;
  text-align: center;
  font-family: Druk Wide Cy TT, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  margin-bottom: 36px;

  @include breakpoint($bk-phone) {
    font-size: 42px;
  }
}

.authorText {
  max-width: 794px;
  width: 100%;
  color: #AEDDFB;
  text-align: center;
  font-size: 33px;
  font-style: normal;
  font-weight: 510;
  font-variant: all-small-caps;

  margin-bottom: 37px;

  @include breakpoint($bk-phone) {
    font-size: 17px;
    max-width: 350px;
  }
}

.form {
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  justify-items: center;
  align-content: center;
  gap: 24px;
}

.genderWrapper {
  width: 100%;
  max-width: 590px;
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

label {
  display: block;

  color: #6D6D6D;
  font-size: 15px;
  font-style: normal;
  font-weight: 274;
  line-height: normal;
  letter-spacing: -0.225px;
  text-transform: uppercase;
}

.outlineBlock {
  @include breakpoint($bk-phone) {
    width: auto;
    height: auto;
    padding: 24px;
  }
  height: 155px;
  border-radius: 30px;
  border: 4px solid #AEDDFB;
  gap: 16px;
  padding: 24px;

  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;

  margin-top: 24px;
  margin-bottom: 3px;

  .titleOutlineBlock {
    color: #6D6D6D;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 510;
    line-height: 20px; /* 66.667% */
    font-variant: all-small-caps;

    @include breakpoint($bk-phone) {
      font-size: 17px;
    }
  }

  .subtitleOutlineBlock {
    color: #6D6D6D;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 274;
    line-height: 34px; /* 141.667% */
    text-transform: lowercase;

    @include breakpoint($bk-phone) {
      font-size: 15px;
    }
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('../public/down.png'); /* Замените на вашу иконку стрелки */
  background-repeat: no-repeat;
  background-position: right center;
  cursor: pointer;
  width: 100%;
  max-width: 590px;
  padding: 10px 16px 10px 10px;
  border: 1px solid rgba(109, 109, 109, 0.51);
  //background: #FFF;
}

input[type="text"],
input[type="tel"],
input[type="email"],
select,
textarea {
  width: 100%;
  max-width: 590px;
  padding: 10px 16px 10px 10px;
  border: 1px solid rgba(109, 109, 109, 0.51);
  background: #FFF;
  outline: none; /* Устанавливаем рамку при фокусе в виде none */
}

.phoneWrapperInput {
  width: 100%;
  max-width: 590px;
}

input[type=tel] {
  padding: 10px 16px 10px 50px;
}

input[type="checkbox"] {
  margin-right: 5px;
}

.cashWrapper {
  display: grid;
  gap: 25px;
}

.Podoidet {
  max-width: 590px;
  padding: 24px 0;
}

.quaWrapper {
  width: 100%;
  display: grid;
  gap: 30px;
  margin-bottom: 24px;
}

footer {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 2;
  height: 300px;

  background-image: url("../public/Vector.png");
  background-size: contain;
  background-position: center;
  background-position-y: bottom;
  background-repeat: no-repeat;

  @include breakpoint($bk-phone) {
    margin-top: 12px;
    height: auto;
    padding: 12px;
    min-height: 170px;
  }
}

.footerWrapper {
  @include breakpoint($bk-phone) {
    padding: 24px;
  }
}

.footerText {
  color: #6D6D6D;
  text-align: center;
  font-size: 25px;
  font-style: normal;
  font-weight: 510;
  @include breakpoint($bk-phone) {
    font-size: 15px;
  }
}

.footerSecond {
  color: #6D6D6D;
  font-size: 20px;
  font-style: normal;
  font-weight: 274;
  margin-top: 12px;
  text-align: center;
  @include breakpoint($bk-phone) {
    font-size: 15px;
    margin-top: 0;
  }
}

.inlineCheckbox {
  width: 100%;
  display: flex;
  flex-flow: wrap;
  gap: 32px;
}

.checkBoxWrapper {
  display: flex;
  align-items: center;
  gap: 8px;

  color: #6D6D6D;
  font-size: 15px;
  font-style: normal;
  font-weight: 590;
  line-height: 130%; /* 19.5px */
}

/* Стили для радиокнопок */
input[type="radio"], .checkbox {
  -webkit-appearance: none; /* Убираем стандартный стиль радиокнопки в Safari */
  appearance: none;
  width: 30px;
  height: 30px;
  border: 1px solid rgba(109, 109, 109, 0.51);
  outline: none;
  cursor: pointer;
  background-image: url("../public/check.png");
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 0; /* Убираем скругление у радиокнопок */
  margin-right: 5px; /* Установите необходимый отступ между радиокнопкой и текстом */
}

/* Стили для отмеченного чекбокса */
input[type="checkbox"]:checked, input[type="radio"]:checked  {
  background-color: #AEDDFB; /* Цвет фона для отмеченного чекбокса */
  border-color: transparent; /* Цвет рамки для отмеченного чекбокса */
}

.checkBoxAgree {
  display: flex;
  align-items: center;
  gap: 7px;

  a {
    color: #6D6D6D;
  }

  color: #6D6D6D;
  font-size: 12px;
  font-style: normal;
  font-weight: 590;
  line-height: 130%; /* 15.6px */

  @include breakpoint($bk-phone) {
    align-items: baseline;
    font-size: 11px;

    label {
      font-size: 11px;
    }
  }
}

.questionTitle {
  font-size: 16px;
  font-weight: 600;
  color: #6D6D6D;
}

.buttonSumbitWrapper {
  display: grid;
  gap: 12px;
  width: 100%;
}

button {
  width: 100%;
  max-width: 320px;
  height: 56px;
  background-color: #AEDDFB;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;

  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 510;
  line-height: normal;
  letter-spacing: -0.225px;
  transition: 0.4s;

  @include breakpoint($bk-phone) {
    max-width: none;
  }
}

button:hover {
  background-color: #0056b3;
  transition: 0.4s;
}

.error {
  color: red;
}

.background {
  background-image: url("../public/Vector.png");
  background-size: contain;
  background-position: center;
  background-position-y: bottom;
  background-repeat: no-repeat;
}