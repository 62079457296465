@import "~breakpoint-sass";

$bk-desktop: 1920px 1440px;
$bk-table-landscape: 1440px 811px;
$bk-table: 811px 611px;
$bk-phone: 611px 0px;

.padding {
    margin-bottom: 45px;
}

.JCC {
    text-align: center;
}

.flex {
    @include breakpoint($bk-phone) {
        flex-flow: column;
    }
}