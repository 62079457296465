.burgerContainer {
    position: relative;
    z-index: 99;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
}

.burgerIcon {
    font-size: 24px;
    cursor: pointer;
}

@keyframes modalFadeIn {
    0% {
        opacity: 0;
        transform: scale(0.8);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.modal {
    position: fixed;
    top: 16px;
    left: 16px;
    width: calc(100% - 32px);
    height: calc(100% - 32px);
    border-radius: 30px;
    background-image: url("../../public/new/bg.png");
    background-size: 400%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    box-sizing: border-box;
    padding: 100px 0;
    opacity: 0;
    transform: scale(0.8);
    animation: modalFadeIn 0.4s ease-out forwards; // Добавляем анимацию появления
}


.modalHeader {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    width: 90%;
    height: 100%;
    max-width: 500px;
    align-items: center;
    position: relative;
}

.nav {
    display: flex;
    flex-flow: column;
    gap: 40px;
}

.modalHeader h1 {
    margin-bottom: 40px;
    font-size: 24px;
}

.closeIcon {
    font-size: 24px;
    cursor: pointer;
    color: white;
}

.modalNav {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.modalNav a {
    color: white;
    text-decoration: none;
    font-size: 18px;
    border-bottom: 1px solid white;
    padding-bottom: 5px;
}

.questionButton {
    width: auto;
    height: 40px;
    background: none;
    border: 1px solid white;
    padding: 8px 42px;
    border-radius: 60px;
    font-size: 1rem;
    color: white;
    cursor: pointer;
    transition: background 0.3s;
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; // 183.333%

    &:hover {
        background: rgba(255, 255, 255, 0.2);
    }
}

.navLink {
    display: flex;
    flex-flow: column;
    cursor: pointer;

    color: var(--White, #FFF);
    text-align: center;
    font-family: "SF Pro Text", sans-serif;
    text-decoration: none;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 14.547px; /* 90.92% */
    letter-spacing: -0.32px;
    text-transform: uppercase;
    transition: 0.5s;
    width: 100%;

    &:hover {
        transition: 0.5s;
        color: #1079AA;
        svg {
            stroke: #1079AA;
        }
    }
}

.closeButton {
    position: fixed !important;
    right: 20px;
    top: 16px;
    height: 56px;
    width: 56px;
    background-color: black;
}


