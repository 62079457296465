body {
  margin: 0;
  font-family: -apple-system, "SF Pro Text", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  padding: 0;
  color: #6D6D6D;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


* {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
  outline: none; /* Убирает обводку у всех элементов */
  -webkit-tap-highlight-color: transparent;
}

@font-face {
  font-family: 'DurkWideCyTT';
  src: url('../public/font/DrukTextWideCyTTBold.woff.ttf') format('truetype');
}

@font-face {
  font-family: "DrukWideCyTTMedium";
  src: url("https://db.onlinewebfonts.com/t/0f0decca41e57850880853f5b0749821.eot");
  src: url("https://db.onlinewebfonts.com/t/0f0decca41e57850880853f5b0749821.eot?#iefix")format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/0f0decca41e57850880853f5b0749821.woff2")format("woff2"),
  url("https://db.onlinewebfonts.com/t/0f0decca41e57850880853f5b0749821.woff")format("woff"),
  url("https://db.onlinewebfonts.com/t/0f0decca41e57850880853f5b0749821.ttf")format("truetype"),
  url("https://db.onlinewebfonts.com/t/0f0decca41e57850880853f5b0749821.svg#DrukWideCyTTMedium")format("svg");
}
