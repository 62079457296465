@import "~breakpoint-sass";

$bk-desktop: 1920px 1440px;
$bk-table-landscape: 1440px 811px;
$bk-table: 811px 611px;
$bk-phone: 611px 0px;

.wrapper {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 80px 0;
  @include breakpoint($bk-phone) {
    padding: 40px 0;
    overflow: hidden;
  }
}

.SubLogo {
  position: absolute;
  margin-top: 30px;
}

.blockSale {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 30px 30px;
  gap: 30px;

  @include breakpoint($bk-phone) {
    flex-flow: column;
    padding: 0 15px;
    align-items: center;
  }

  @include breakpoint(1110px 811px) {
    flex-flow: column;
    padding: 0 15px;
    align-items: center;
  }

  p {
    font-size: 14px;
  }
}

.SaleWrapper {
  display: flex;
  flex-flow: column;
  text-align: center;
  gap: 30px;
  padding: 30px;
  height: 100%;
  width: 100%;
  max-width: 500px;
  background-color: white;
  border-radius: 10px;
  border: 2px solid #D9D9D9;

  svg {
    transform: scale(1.3);
  }

  @include breakpoint($bk-phone) {
    svg {
      transform: scale(1);
    }
  }
}

.SalePage {
  display: flex;
  flex-flow: column;
}

.TitlePage {
  color: rgba(109, 109, 109, 0.90);
  text-align: center;
  text-shadow: 4px 4px 3px #AEDDFB;
  font-family: DurkWideCyTT;
  font-size: 29px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.PriceTitle {
  color: #AEDDFB;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: DurkWideCyTT;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 160% */
  letter-spacing: -0.75px;
}

.Subtitle {
  color: rgba(109, 109, 109, 0.90);
  text-align: center;
  //font-family: SF Pro;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 0px; /* 0% */
  letter-spacing: -0.26px;
  text-transform: uppercase;
}

.WrapperDescription {
  width: 100%;
  min-height: 100px;
  overflow: visible;
  border-radius: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 24px 0;

  background-image: url("../public/1.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;

  @include breakpoint($bk-phone) {
    padding: 0;
  }
}

.bg {
  width: 100%;
  min-height: 100px;
  overflow: visible;
  border-radius: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  //margin-top: 2px;
  padding: 16px 0;
  background-image: url("../public/2.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.bg700 {
  min-height: 100px;
  background-position: center;
  padding: 35px 0;

  @include breakpoint($bk-phone) {
    padding: 24px 0;
  }
}

.add {
  padding: 9px 0;
}

.Description {
  padding: 16px 14px;
}

.WrapperWhat {
  display: flex;
  flex-flow: column;
  width: auto;
  gap: 24px;

  .What {
    display: flex;
    text-align: left;
    gap: 12px;
  }
}

.forWhat1 {
  font-weight: 700;
}
.light {
  font-weight: 400;
}

.forWhat {
  color: #BDBDBD;
}

span {
  color: #AEDDFB;
}

.DropDown {
  height: auto;

  .HeaderDropDown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 4px;
    border-bottom: 1px solid #6D6D6D;

    svg {
      cursor: pointer;
    }
  }
}

.ButtonSecondary {
  width: 100%;
  background: #F0F0F0;
  color: #6D6D6D;
  transition: 0.4s;

  &:hover {
    background: #F0F0F0;
    color: #6D6D6D;
    filter: drop-shadow(2px 2px 18px #AEDDFB);
    transition: 0.4s;
  }
}

.ButtonPrimary, Link {
  width: 100%;
  filter: drop-shadow(2px 2px 10px #AEDDFB);
}

.TitlePrice {
  color: #AEDDFB;
  text-align: center;
  font-family: DurkWideCyTT;
  font-size: 35px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 160% */

  @include breakpoint($bk-phone) {
    font-size: 30px;
  }
}

.Shadow {
  color: #FFF;
  text-shadow: 1px 4px 3px #8FCEF6;
}

.ButtonLink {
  color: #6D6D6D;
  cursor: pointer;
  text-transform: uppercase;
  transition: 0.4s;

  color: rgba(109, 109, 109, 0.90);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 13px; /* 130% */
  text-decoration-line: underline;
  text-transform: uppercase;

  @include breakpoint($bk-phone) {
    font-size: 12px;
  }

  &:hover {
    color: #AEDDFB;
    transition: 0.4s;
  }
}

button {
  max-width: none;
}

.AccordionContent {
  display: flex;
  flex-flow: column;
  gap: 24px;

  text-align: left;
  padding: 24px 0;

  .AccordionItem {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    gap: 64px;
  }

  .WeekTitle {
    color: #6D6D6D;
    font-family: SF Pro, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
  }
}

h3 {
  color: rgba(109, 109, 109, 0.90);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.28px;
}

h4 {
  color: #AEDDFB;
}

.Blue {
  background: #F2F9FD;
  border: none;
}

.Sale700 {
  margin-top: 48px;
}

.Contacts {
  display: flex;
  flex-flow: column;
  text-align: right;

  @include breakpoint($bk-phone) {
    text-align: center;
  }
}

.Contact {
  width: 100%;
  flex-flow: column;
  align-items: center;
  padding: 80px 30px;
  text-align: center;

  h1 {
    color: rgba(109, 109, 109, 0.90);
    text-align: center;
    text-shadow: 4px 4px 3px #AEDDFB;
    font-family: DurkWideCyTT,sans-serif;
    font-size: 29px;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
  }

  p {
    font-size: 16px;
  }

  button {
    max-width: 212px;
  }

  svg {
    @include breakpoint($bk-phone) {
      max-width: 300px;
    }
  }
}

.footer {
  width: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  background-color: #6DC6FE;
  color: white;

  svg {
    @include breakpoint($bk-phone) {
      max-width: 300px;
    }
  }

  @include breakpoint($bk-phone) {
    padding: 24px;
  }

  a {
    color: white;
  }

  .EP {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
    gap: 32px;



    @include breakpoint($bk-phone) {
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 16px;
    }
  }

  .docs {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto 1fr; // Левый и правый элементы растягиваются
    gap: 32px;

    & > a:nth-child(2) {
      text-align: center; // Центрируем средний элемент
    }

    @include breakpoint($bk-phone) {
      grid-template-columns: 1fr; // Переход на одну колонку на мобильных
      align-items: center;
      text-align: center;
      height: auto;
    }
  }
}

.center {
  text-align: center;
  align-content: center;
}

.right {
  text-align: right;
}




