.App {
    display: grid;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.SuccessWrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    padding: 24px;

    text-align: center;
    gap: 24px;
}

.Service {
    display: grid;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}